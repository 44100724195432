import i18n from "@/libs/i18n";

export default [
  {
    title: i18n.messages.Home,
    route: "home",
    icon: "HomeIcon",
  },
  {
    title: i18n.messages.Productos,
    route: "products",
    icon: "SearchIcon",
  },
  {
    title: i18n.messages.Carrito,
    route: "cart",
    icon: "ShoppingCartIcon",
    tagVariant: "secondary",
  },
  {
    title: i18n.messages.Pedidos,
    route: "orders",
    icon: "BookOpenIcon",
  },
];
