<template>
  <div>
    <b-button
      v-if="currentCustomer"
      @click="setShowModalCustomers(true)"
      size="sm"
      variant="info"
    >
      {{ currentCustomer.NOMBREEMPRESA }}
    </b-button>
    <b-button
      v-else
      @click="setShowModalCustomers(true)"
      size="sm"
      variant="info"
    >
      {{ $i18n.messages.selectClient }}
    </b-button>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  components: {
    BButton,
  },
  computed: {
    ...mapGetters({
      currentCustomer: "customers/getCurrentCustomer",
    }),
  },
  methods: {
    ...mapMutations({
      setShowModalCustomers: "customers/setShowModalCustomers",
    }),
  },
};
</script>
